<style>
/************************************************************
** 请将全局样式拷贝到项目的全局 CSS 文件或者当前页面的顶部 **
** 否则页面将无法正常显示                                  **
************************************************************/

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Microsoft Yahei", sans-serif;
}

body * {
  box-sizing: border-box;
  flex-shrink: 0;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}
</style>
<template>
  <div class="flex-col page">
    <div class="flex-col group space-y-40">
      <div class="flex-col group_2">
        <div class="flex-row space-x-4">
          <img class="image_4" :src="productLogo" alt="" />
          <span class="text">{{ productName }}</span>
        </div>
        <div class="auditView flex-row justify-center">
          <img
            class="image_2"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16703134750089816703.png"
          />
          <span class="font_1 auditTitle">恭喜您，已通过初审! </span>
        </div>
        <div class="auditSubtitle">{{ titleSring }}</div>
        <div class="flex-col">
          <div class="flex-col section">
            <div class="flex-row justify-between group_3">
              <div class="flex-row space-x-8">
                <span class="text_3">¥</span>
                <van-field
                  type="digit"
                  placeholder="预估额度55000"
                  class="inp-elem"
                  maxLength="6"
                  v-model="borrowCount"
                  @change="changeBorrowCount"
                  @clear="clearBorrowCount"
                  clearable
                />
              </div>
            </div>
            <div class="flex-row justify-center group_4">
              <div class="flex-col justify-start text-wrapper">
                <span class="text_5">限时优惠</span>
              </div>
              <div class="flex-row justify-center group_5">
                <span class="font_3 text_6">日利率</span>
                <span class="text_8">0.025%</span>
              </div>
              <div class="flex-col justify-start group_6">
                <span class="font_3 text_7">0.035%</span>
                <img
                  class="image_6"
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16703134755636471950.png"
                />
              </div>
              <span class="font_3 text_9">(一千元用一天只需0.25元)</span>
            </div>
          </div>
          <div class="flex-col group_7 space-y-18">
            <div class="flex-row justify-evenly group_8">
              <img
                class="image_7"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16703134750125114836.png"
              />
              <span class="font_3 text_10"
                >次日起可还款，免手续费，免剩余天数利息</span
              >
              <img
                class="image_7"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16703134750115478401.png"
              />
            </div>
            <div class="flex-col space-y-20">
              <div class="flex-col space-y-10">
                <div
                  class="flex-row justify-between list-item"
                  :key="i"
                  v-for="(item, i) in list"
                  @click="clickItemAction(item, i)"
                >
                  <span class="font_1 text_11">{{ item.title }}</span>
                  <div class="flex-col items-end space-y-10">
                    <span class="font_4">{{
                      item.monthlyRepayment +
                      Number(item.monthlyRepaymentAcount).toFixed(2)
                    }}</span>
                    <span class="font_3 text_12">{{
                      item.interest + Number(item.interestAcount).toFixed(2)
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="flex-row justify-between group_9">
                <span class="font_4 text_17">收/还款账户</span>
                <div class="flex-row">
                  <img
                    class="image_8"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/fea5200410ddfa7908d6e6d7935b8003.png"
                  />
                  <img
                    class="image_9"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16703134754010746189.png"
                  />
                  <img
                    class="image_9"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16703134754394625130.png"
                  />
                  <img
                    class="image_9"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16703134755124262930.png"
                  />
                  <span class="text_16">…</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        class="flex-col justify-start items-center button"
        @click="handleSubmit"
      >
        <span class="font_2 text_18">{{ btnTxt }}</span>
      </button>
    </div>

    <!-- 提示浏览器打开遮罩层 -->
    <SharePop :show="showPop" @change="handlePopChange" />
    <!-- 下载跳转提示 -->
    <div class="van-overlay" v-show="showDownloadPop">
      <img
        class="icon-download"
        src="../../public/img/register/download_bg.png"
      />
    </div>

    <van-popup
      v-model="isShowPop"
      position="bottom"
      closeable
      @close="handelClosePop"
      v-if="this.popData.productName"
    >
      <div class="pop-countent">
        <div class="title">
          <span class="span-icon"></span>
          <span class="title-text"
            >恭喜您已通过{{ popData.productName }}初审</span
          >
        </div>
        <div class="countent">
          <div class="num">{{ popData.loanAmount }}元</div>
          <div class="tip">额度由{{ popData.productName }}提供</div>
        </div>
        <div class="agree-txt">
          <van-checkbox
            v-model="checked"
            class="check-box"
            shape="square"
          ></van-checkbox>
          <span class="txt"
            >我已阅读并同意<i @click="handleProtocol(9)"
              >《个人信息查询授权书》</i
            ></span
          >
        </div>
        <div class="btn-pop" @click="handlePopBtn">去提现</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import * as API_Common from "@/api/common";
import "@/css/stagingAssessment.scss";
window.onpageshow = function (event) {
  if (event.persisted) {
    window.location.reload();
  }
};
export default {
  components: {},
  data() {
    return {
      channel: this.$route.query.source,
      //借款金额
      borrowCount: undefined,
      //年利率7.2
      rate: 0.091,
      //月利率
      monthRate: 12,
      defaultInspectionRef: undefined,
      params: {
        pageNum: 1,
        pageSize: 5,
      },
      titleSring: undefined,
      isFinsh: true,
      backFlag: window.localStorage.getItem("backFlag"),
      isShowPop:
        !window.localStorage.getItem("closePop") &&
        window.localStorage.getItem("backFlag"),
      showDownloadPop: false,
      checked: false,
      downAppUrl: "",
      showPop: false,
      productName: "",
      productLogo: "",
      popData: {},
      recommendProduct: {},
      btnTxt: "立即下载",

      list: [
        {
          id: 0,
          title: "分12月还",
          select: true,
          monthlyRepayment: "首期预估还 ¥",
          monthlyRepaymentAcount: "5000.42",
          interest: "借满12个月,总利息:¥",
          interestAcount: "5005.00",
          selectImg: "../assets/images/stagingAssessment/img_select.png",
        },
        {
          id: 1,
          title: "分6月还",
          select: false,
          monthlyRepayment: "首期预估还 ¥",
          monthlyRepaymentAcount: "9583.75",
          interest: "借满6个月,总利息:¥",
          interestAcount: "2502.50",
          selectImg: "../assets/images/stagingAssessment/img_unselect.png",
        },
        {
          id: 2,
          title: "分3月还",
          select: false,
          monthlyRepayment: "首期预估还 ¥",
          monthlyRepaymentAcount: "18750.42",
          interest: "借满3个月,总利息:¥",
          interestAcount: "1251.25",
          selectImg: "../assets/images/stagingAssessment/img_unselect.png",
        },
      ],
    };
  },
  created() {
    this.getDownloadUrl();
    this.getIsShieldCity();
    if (
      window.localStorage.getItem("closePop") == null &&
      window.localStorage.getItem("backFlag") &&
      this.popData.productId
    ) {
      this.isShowPop = true;
    }
  },
  methods: {
    //全部借出
    clickBorrowAllBtnDidAction() {
      this.borrowCount = 200000;
      this.changeBorrowCount();
    },
    // 改变借款金额
    changeBorrowCount() {
      if (this.borrowCount < 10000) {
        this.borrowCount = 10000;
      }
      if (this.borrowCount > 200000) {
        this.borrowCount = 200000;
      }
      this.monthRate = this.rate / 12;
      this.list[0].monthlyRepaymentAcount =
        (this.borrowCount * this.rate + parseInt(this.borrowCount)) / 12;
      this.list[1].monthlyRepaymentAcount =
        (parseInt(this.borrowCount) +
          parseInt(this.borrowCount) * this.monthRate * 6) /
        6;

      this.list[2].monthlyRepaymentAcount =
        (parseInt(this.borrowCount) +
          parseInt(this.borrowCount) * this.monthRate * 3) /
        3;
      this.list[0].interestAcount = this.borrowCount * this.rate;
      this.list[1].interestAcount = this.borrowCount * this.monthRate * 6;
      this.list[2].interestAcount = this.borrowCount * this.monthRate * 3;
    },
    //清空借款金额
    clearBorrowCount() {
      this.list[0].monthlyRepaymentAcount = 0;
      this.list[1].monthlyRepaymentAcount = 0;
      this.list[2].monthlyRepaymentAcount = 0;
      this.list[0].interestAcount = 0;
      this.list[1].interestAcount = 0;
      this.list[2].interestAcount = 0;
    },
    clickWithdrawalDidAction() {},
    //选中列表item
    clickItemAction(item, index) {
      this.list.forEach((it) => {
        if (it.id == index) {
          it.select = true;
        } else {
          it.select = false;
        }
      });
    },
    // 关闭弹窗 如果点击关闭按钮之后，则以后不再弹窗
    handelClosePop() {
      this.isShowPop = false;
      window.localStorage.setItem("closePop", "ture");
      window.localStorage.removeItem("backFlag");
    },
    //点击弹窗产品去申请
    handlePopBtn() {
      if (!this.checked) {
        this.$toast("请勾选同意个人信息查询授权书");
        return;
      }
      API_Common.buried({
        productId: this.popData.productId,
        showPage: 5,
      }).then((res) => {
        console.log(res);
        if (200 === res.code) {
          setTimeout(() => {
            window.localStorage.setItem("closePop", "ture");
            window.localStorage.removeItem("backFlag");
            window.location.href = this.popData.linkUrl;
          }, 500);
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    handleSubmit() {
      let isWeixin = this.isWeixin();
      if (isWeixin && this.channel != "wxgzh" && this.channel != "wxgzh1") {
        this.showPop = true;
      } else {
        if (this.popData.isShieldCity || !this.recommendProduct.id) {
          if (isWeixin && this.channel == "wxgzh" && this.channel != "wxgzh1") {
            this.showPop = true;
          } else {
            this.handleDownload();
          }
        } else {
          API_Common.buried({
            productId: this.recommendProduct.id,
            showPage: 5,
          }).then((res) => {
            if (200 === res.code) {
              setTimeout(() => {
                window.localStorage.setItem("backFlag", "true");
                window.location.href = this.recommendProduct.linkUrl;
              }, 500);
            } else {
              this.$toast.fail(res.msg);
            }
          });
        }
      }
    },

    getDownloadUrl() {
      var that = this;
      API_Common.getDownloadUrl(this.channel).then((res) => {
        if (res.code === 200) {
          that.downAppUrl = res.data.url;
        }
      });
    },
    // 根据渠道code读取产品推荐信息 将权重最大的进行显示
    getChannleProductList() {
      API_Common.getChannleProductList(this.channel).then((res) => {
        if (res.code === 200) {
          let dataArr = res.data;
          let maxItem = dataArr.reduce(function (prev, current) {
            return prev.sort > current.sort ? prev : current;
          });
          if (dataArr.length > 0) {
            this.recommendProduct = maxItem;
          }
        }
      });
    },
    //获取是否屏蔽城市
    getIsShieldCity() {
      API_Common.getIsShieldCity(this.channel).then((res) => {
        if (res.code === 200) {
          if (res.data == false) {
            //当前城市未屏蔽
            this.getChannleProductList();
          }
          this.getPopProduct();
        }
      });
    },
    // 根据渠道code和进行到哪一步查询配置H5弹窗产品权重最大的值
    getPopProduct() {
      API_Common.getPopItem(this.channel).then((res) => {
        if (res.code === 200) {
          this.popData = res.data;
          if (res.data.isShieldCity || !this.recommendProduct.id) {
            this.btnTxt = "立即下载";
            this.productName = "融e钱包";
            this.titleSring = "";
            this.productLogo =
              "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4fbef2b3a6a255fb03a1fe1316440f344183b0446d06274f7e1eddd93946cc08";
          } else {
            this.btnTxt = "立即提现";
            this.productLogo = this.recommendProduct.imgUrl;
            this.titleSring = `您的借款将由合作方${this.recommendProduct.name}提供。`;
            this.productName = this.recommendProduct.name;
          }
        }
      });
    },

    handleProtocol(pageType) {
      window.location.href = "/protocol.html?pageType=" + pageType;
      localStorage.setItem("isClickAuth", "YES");
    },

    handlePopChange(show) {
      this.showPop = show;
    },

    handleGoLink(item) {
      API_Common.buried({
        productId: item.productId,
        showPage: 5,
      }).then((res) => {
        console.log(res);
        if (200 === res.code) {
          setTimeout(() => {
            window.localStorage.setItem("backFlag", "true");
            window.location.href = item.linkUrl;
          }, 500);
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    handleDownload() {
      var that = this;
      if (that.channel != "wxgzh" && that.channel != "wxgzh1") {
        that.showDownloadPop = true;
        setTimeout(function () {
          window.location.href = that.downAppUrl;
        }, 3000);
      }
    },
  },
};
</script>

<style>
.icon-download {
  width: 6.8rem;
  height: 7.3rem;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
